/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants active, hover {
    .scale-98 {
      transform: scale(0.98);
    }

    .scale-102 {
      transform: scale(1.02);
    }

    .ul::after {
      content: '';
      position: absolute;
      bottom: 8px;
      display: inline-block;
      height: 2px;
      width: 120%;
      left: calc(-10%);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.01) 0%,
        rgba(255, 255, 255, 0.15) 35%,
        rgba(255, 255, 255, 0.25) 50%,
        rgba(255, 255, 255, 0.15) 65%,
        rgba(0, 0, 0, 0) 100%
      );
      opacity: 0.6;
    }
  }

  .td-160 {
    transition-duration: 500ms;
  }

  .tlxneg {
    transform: translateX(-400%);
  }

  .tlxpos {
    transform: translateX(400%);
  }

  .ul::after {
    content: '';
    opacity: 0;
    transition: opacity 0.75s ease-out;
  }

  .ul_o::after {
    opacity: unset !important;
  }

  .-z-1 {
    z-index: -1;
  }

  .min-h-36 {
    min-height: 36rem;
  }

  .w-fit {
    width: fit-content;
  }

  .h-fit {
    height: fit-content;
  }

  a {
    color: rgb(87 114 218) !important;
  }

  .max-w-40 {
    max-width: 40%;
  }

  .h-102 {
    height: 28rem;
  }

  .h-112 {
    height: 32rem;
  }

  .h-128 {
    height: 36rem;
  }

  .ssw-0 {
    scrollbar-width: none !important;
  }

  .ssw-0::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .max-h-112 {
    max-height: 32rem;
  }

  .max-h-128 {
    max-height: 36rem;
  }

  .max-h-132 {
    max-height: 42rem;
  }

  .max-h-156 {
    max-height: 52rem;
  }

  .underline {
    text-decoration: none !important;
    position: relative;
  }

  .underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    height: 2px;
    width: 110%;
    left: calc(-5%);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.01) 0%,
      rgba(171, 209, 181, 0.15) 25%,
      rgba(171, 209, 181, 0.25) 50%,
      rgba(171, 209, 181, 0.15) 75%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bg-transp {
    background-color: rgba(50, 55, 50, 0.3);
  }

  .bg-transp-75 {
    background-color: rgba(75, 80, 75, 0.3);
  }

  .bg-transp-100 {
    background-color: rgba(100, 105, 100, 0.3);
  }

  body {
    overflow-x: hidden;
    background: linear-gradient(180deg, rgb(26, 29, 35), rgb(26, 30, 25));
    height: 100vh;
  }

  #modal {
    background-color: rgb(17, 23, 26);
  }

  #modal_stage {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
